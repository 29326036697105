
.container {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../images/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  padding-bottom: 100px;
}

.title_container {
  display: flex;
  padding: 7px 8%;
  justify-content: center;
}

.title {
  text-align: center;
  padding: 7px 0;
  font-size: 70px;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  line-height: 120px;
  background: linear-gradient(120deg,#1c99fe 20.69%,
    #7644ff 50.19%,#fd4766 79.69%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;

  @media screen and (max-width: 1186px) {
    font-size: 9vw;
    line-height: 12vw;
  }

}
