.mini_info {
  font-size: 0.865rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 4px;
  letter-spacing: 0.01071em;
  background-color: #e8f4fd;
  padding: 8px;
  display: flex;

}

.mini_info_icon {
  width: 22px;
  height: 22px;
  display: flex;
  opacity: 0.9;
  font-size: 22px;
  margin-right: 12px;

  @media screen and (max-width: 500px) {
    width: 22px;
    height: 22px;
  }

}
.mini_info_icon_svg {
  width: 22px;
  height: 22px;

}

.mini_info_text {
  word-break: break-all;
}