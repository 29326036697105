.main {
  padding: 120px 0;
  background-color: #faebbf;
  font-family: "Poppins",sans-serif;
}

.container {
  display: flex;
  margin: 0 100px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1040px) {
    flex-direction: column;
  }
}

.image_container {
  margin-right: 20px;
}

.image {
  background: url('../../images/sloth-sleep.gif');
  background-size: contain;
  background-repeat: no-repeat;
  width: 483px;
  height: 386px;

  @media screen and (max-width: 1295px) {
    width: 403px;
    height: 306px;
  }
  @media screen and (max-width: 1040px) {
    width: 336px;
    height: 236px;
  }

  @media screen and (max-width: 568px) {
    display: none;
  }
}

.description_container {
  max-width: 696px;
  min-width: 500px;

  @media screen and (max-width: 568px) {
    min-width: 300px;
  }

}

.description_icon {
  width: 60px;
  height: 60px;
  background-color: #fd4766;
  background-image: url('../../images/tg.svg');
  background-size: 30px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 47%;;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  margin-bottom: 17px;
}

.title {
  line-height: 1.3;
  margin-bottom: 35px;
  font-size: 50px;
  font-weight: 600;
  text-align: left;
  @media screen and (max-width: 1040px) {
    font-size: 36px;
  }
  
}

.description_text, .description_text_second {
  font-weight: 300;
  color: #757575;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.description_link {
  font-weight: 500;
  color: #1d1d24;
  text-decoration: none;

  &:hover {
    color: #fd4766;
  }
}

.description_link_buy {
  font-size: 24px;
  font-weight: 600;
  position: relative;
  background: linear-gradient(120deg,#1c99fe 20.69%
  ,#7644ff 50.19%,#fd4766 79.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  &:hover {
    &::after {
      transition: all .3s cubic-bezier(.645,.045,.355,1);
      width: 100%;
    }
  }

  &::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 40px;
    height: 2px;
    opacity: .75;
    background: linear-gradient(120deg,#1c99fe 20.69%,
    #7644ff 50.19%,#fd4766 79.69%);
  }
}