.info_wrap {
  display: flex;
  margin-top: 59px;

  @media screen and (max-width: 950px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media screen and (max-width: 551px) {
    
  }
}

.info_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  max-width: 35%;

  @media screen and (max-width: 1186px) {
    padding-left: 0px;
    padding-right: 0px;
    max-width: none;
  }

  @media screen and (max-width: 990px) {
    width: 360px;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 551px) {
    margin-bottom: 50px;
  }

  div {
    margin: 10px;
  }

  
}

.info_icon_1,
.info_icon_2,
.info_icon_3,
.info_icon_4 {
  width: 60px;
  height: 60px;
}

.info_icon_1 {
  background: url("../../images/share.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.info_icon_2 {
  background: url("../../images/buy.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.info_icon_3 {
  background: url("../../images/hold.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.info_icon_4 {
  background: url("../../images/adopt.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.info_title {
  color: #fff;
}

.info_text {
  color: #ffffff;
  text-align: center;
  padding: 0 20px;
}

.info_wrap_second {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > .info_item_second > div.info_icon_second {
    width: 45px;
    height: 45px;
    background-size: contain;
  }

  & > .info_item_second:nth-child(1) > div.info_icon_second {
    background-image: url('../../images/burn.svg');
  }

  & > .info_item_second:nth-child(2) > div.info_icon_second {
    background-image: url('../../images/redistribution.svg');
  }

  & > .info_item_second:nth-child(3) > div.info_icon_second {
    background-image: url('../../images/lock.svg');
  }
  
  & > .info_item_second:nth-child(4) > div.info_icon_second {
    background-image: url('../../images/supply.svg');
  }

  & > .info_item_second:nth-child(5) > div.info_icon_second {
    background-image: url('../../images/burned_supply.svg');
  }

  & > .info_item_second:nth-child(6) > div.info_icon_second {
    background-image: url('../../images/wallet.svg');
  }
  
}

.info_item_second {
  border-radius: 10px;
  background-color: #fff;
  margin: 30px 30px;
  padding: 40px 30px;
  width: 320px;
}

.info_title_second, .info_text_second {
  margin-top: 19px;
}

.info_wrap_third {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 870px) {
    flex-wrap: wrap;
  }

  & > .info_item_third > div.info_icon_third {
    width: 35px;
    height: 35px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  & > .info_item_third:nth-child(1) > div.info_icon_third {
    background-image: url('../../images/blacklist.svg');
  }

  & > .info_item_third:nth-child(2) > div.info_icon_third {
    background-image: url('../../images/decoy.svg');
  }

  & > .info_item_third:nth-child(3) > div.info_icon_third {
    background-image: url('../../images/time.svg');
  }
  
  & > .info_item_third:nth-child(4) > div.info_icon_third {
    background-image: url('../../images/max.svg');
  }

  & > .info_item_third:nth-child(1) > .info_title_third {
    color: #1d99fd;
  }

  & > .info_item_third:nth-child(2) > .info_title_third {
    color: #5b5fff;
  }

  & > .info_item_third:nth-child(3) > .info_title_third {
    color: #a645c7;
  }

  & > .info_item_third:nth-child(4) > .info_title_third {
    color: #fd4665;
  }
}

.info_item_third {
  margin: 30px 30px;
  max-height: 120px;
  width: 230px;

}

.info_title_third {
  font-weight: 700;
  font-size: 30px;

  @media screen and (max-width: 870px) {
    font-size: 25px;
  }

}

.info_text_third {
  font-size: 18px;
  line-height: 1.67;
  color: #fff;
}