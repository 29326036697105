.main {
  padding: 120px 0;
  background: #fafafb;
}

.info_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtitle {
  background: linear-gradient(120deg, #1c99fe 20.69%, 
  #7644ff 50.19%, #fd4766 79.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  letter-spacing: .5px;
  line-height: 18px;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 12px;
  width: 162.2px;
}

.title {
  font-size: 50px;
  font-weight: 600;
  line-height: 62px;
  margin-bottom: 20px;

  @media screen and (max-width: 374px) {
    font-size: 40px;
  }
}

.description {
  font-size: 18px;
  line-height: 30px;
  color: #757575;
  font-weight: 400;
}
