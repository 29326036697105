.main {
  background-color: #000;
  padding: 100px 0;
}

.main_wrap {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 340px) {
    justify-content: space-between;
  }

  & .wrap:nth-child(2) {
      margin-left: 40px;

      @media screen and (max-width: 340px) {
        margin-left: 0;
      }
  }

  & .wrap:nth-child(2) .link_wrap {
    flex-direction: row;
    justify-content: space-between;
  }
}

.wrap {
  margin: 0 20px;
}

.link_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .alt_link:nth-child(1) .link_icon {
    background-image: url('../../images/twitter.svg');
  }

  & .alt_link:nth-child(2) .link_icon {
    background-image: url('../../images/telegram.svg');
  }

  & .alt_link:nth-child(3) .link_icon {
    background-image: url('../../images/medium.svg');
  }
}

.title {
  color: #fff;
  font-size: 20px;
}

.link {
  color: #757575;
  font-size: 16px;
  text-decoration: none;
  margin-top: 20px;
  width: fit-content;
  position: relative;
  &:hover {
    color: #fd4766;
    &::after {
      transition: all .3s cubic-bezier(.645,.045,.355,1);
      width: 100%;
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      height: 2px;
      opacity: .75;
      background: linear-gradient(120deg,#1c99fe 20.69%,
      #7644ff 50.19%,#fd4766 79.69%);
    }
  }
}

.alt_link {
  text-decoration: none;
  margin-top: 20px;
}

.link_icon {
  width: 16px;
  height: 16.99px;
  background-repeat: no-repeat;
}