.main {
  padding: 60px 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 502px) {
    padding: 60px 0px;
    justify-content: space-around;
  }
}

.link,
.alt_link {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 20px 0;
  margin-left: 35px;
  text-decoration: none;
  @media screen and (max-width: 502px) {
    margin-left: 0;
  }
  &.link:hover {
    color: #fd4766;
  }
}

.link {
  @media screen and (max-width: 502px) {
    margin-left: 0;
    margin-right: 23px;
  }
}

.alt_link {
  border: 2px solid #ffffff33;
  padding: 16px 40px;
  border-radius: 500px;
  transition: all 0.4s ease-in-out;
  &:hover {
    background-color: #fd4766;
  }

  @media screen and (max-width: 402px) {
    padding: 16px 19px;
  }
}
