.main {
  background-color: #363846;
  padding: 120px 0;
  color: #fff;
}

.subtitle {
  font-weight: 600;
  letter-spacing: .5px;
  line-height: 18px;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 12px;
  background: linear-gradient(120deg,#1c99fe 20.69%,
  #7644ff 50.19%,#fd4766 59.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.title {
  font-size: 50px;
  font-weight: 600;
  line-height: 62px;
  margin-bottom: 60px;
  text-align: center;
}

.wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.exchange_image {
  background-image: url('../../images/exchange.png');
  width: 495px;
  height: 558px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 20px;

  @media screen and (max-width: 1280px) {
    width: 395px;
    height: 458px;
  }

  @media screen and (max-width: 570px) {
    width: 285px;
    height: 348px;
    margin-left: 0;
  }
}

.exchange_info {
  max-width: 510px;
  @media screen and (max-width: 570px) {
    padding: 16px 12px;
  }
}

.exchange_title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.4074;
  margin-bottom: 15px;
}

.exchange_list {
  list-style: none;
  padding: 0;

  & > .exchange_item  > .exchange_link, .exchange_color_word{
    font-weight: 700;
  }

  & > .exchange_item:nth-child(1) > .exchange_link {
    color: #ff8f3c;
  }
  & > .exchange_item:nth-child(2) > .exchange_link {
    color: #fd4766;
  }
  & > .exchange_item:nth-child(4) > .exchange_link {
    color: #ff8f3c;
  }

  & > .exchange_item:nth-child(7) > .exchange_color_word {
    color: #fd4766;

    @media screen and (max-width: 570px) {
      font-size: 12px;
    }
  }
  & > .exchange_item:nth-child(8) > .exchange_color_word:nth-child(1) {
    color: #ff8f3c;
  }
  & > .exchange_item:nth-child(8) > .exchange_color_word:nth-child(2) {
    color: #337dff;
  }
}

.exchange_item {
  padding: 16px 0;
  line-height: 1.67;
  font-size: 18px;
}

.exchange_link {
  text-decoration: none;
  color: #ff8f3c;
}
