.bg_blue {
  background-color: #1d99fd;
}

.bg_darkblue  {
  background-color: #5b5fff;
}

.bg_violet  {
  background-color: #a645c7;
}
.bg_red  {
  background-color: #fd4665;
}

.border_blue {
  border-color: #1d99fd;
}

.border_darkblue  {
  border-color: #5b5fff;
}
.border_violet {
  border-color: #a645c7;
}
.border_red  {
  border-color: #fd4665;
}

.main {
  background-color: #ebf7f3;
  padding: 120px 0;
}

.subtitle {
  font-weight: 600;
  letter-spacing: .5px;
  line-height: 18px;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 12px;
  background: linear-gradient(120deg,#1c99fe 20.69%,
  #7644ff 50.19%,#fd4766 59.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.title {
  font-size: 50px;
  font-weight: 600;
  line-height: 62px;
  margin-bottom: 60px;
  text-align: center;
}

.wrap {
  display: flex;
  color: #fff;
  text-align: right;
  justify-content: center;
}

.list {
  list-style: none;
  padding: 0;

  & .list_wrap:nth-child(1) .list_item  {
    height: 214px;;
  }

  & .list_wrap:nth-child(2) .list_item  {
    height: 174px;;
  }

  & .list_wrap:nth-child(3) .list_item  {
    height: 203px;;
  }

  & .list_wrap:nth-child(4) .list_item  {
    height: 229px;
  }

  @media screen and (max-width: 465px) {

    & .list_wrap:nth-child(2) .list_item  {
      height: 224px;;
    }

    & .list_wrap:nth-child(3) .list_item  {
      height: 253px;;
    }
  
    & .list_wrap:nth-child(4) .list_item  {
      height: 259px;
    }
  }

}

.list_item {
  margin-right: 20px;
  width: 411px;
  padding: 18px 16px;
  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -ms-border-radius: 18px;
  -o-border-radius: 18px;

  @media screen and (max-width: 465px) {
    margin-right: 0;
    width: none;
    height: none;
  }
}

.list_wrap {
  display: flex;

  @media screen and (max-width: 465px) {
    margin-bottom: 25px;
    width: 281px;
  }
  
}

.phase_title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 15px;
}

.phase_list {
  list-style: none;
  font-size: 18px;
  padding: 0 0;
}

.phase_item {
  line-height: 1.67;

  @media screen and (max-width: 465px) {
    text-align: left;
  }
}

.phase_item_done {
  &:before {
    content: "";
    color: inherit;
    display: inline-flex;;
    margin-right: 5px;
    background: url('../../images/done.svg') no-repeat;
    width: 15px;
    height: 15px;
  }
}

.phase_item_undone {
  &:before {
    content: "";
    color: inherit;
    display: inline-flex;;
    margin-right: 5px;
    background: url('../../images/undone.svg') no-repeat;
    width: 15px;
    height: 15px;
  }
}

.line_wrap {
  width: 12px;
  height: 312px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media screen and (max-width: 465px) {
    display: none;
  }
}

.line_point {
  padding: 4px;
  align-self: baseline;
  box-shadow: 0px 3px 1px -2px #00000033,
   0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  margin-bottom: 8px;
  
}

.line {
  width: 2px;
  height: 284px;
  
}

